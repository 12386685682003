import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const StaticPageTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="max-w-4xl mt-6 mx-3 px-10 py-6 bg-white rounded-lg shadow-md"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="flex justify-between items-center">
          <span className="font-light text-sm text-gray-600">
            最終更新日: {post.frontmatter.modifieddate}
          </span>
        </header>
        <section className="mt-2">
          <h1 className="text-2xl text-gray-700 font-bold">
            {post.frontmatter.title}
          </h1>
          <div
            className="mt-2 text-gray-800 markdown"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </section>
        <hr />
        <footer className="mt-10 mb-5">
          <Link to="/">← トップページへ</Link>
        </footer>
      </article>
    </Layout>
  )
}

export default StaticPageTemplate

export const pageQuery = graphql`
  query StaticPageBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        modifieddate(formatString: "yyyy/MM/DD")
        description
      }
    }
  }
`
